import * as Yup from "yup";
import { EFCDataRecord } from "../../../shared/types/types";
import { getIncomeProtectionAllowance, getStateTaxAllowance, stateTaxAllowance } from "../../../shared/utils/efcUtils";

type Props = {
  form: EFCDataRecord;
  handleSave: (formData: EFCDataRecord, efc: number) => void;
};

export const useEFCForm = ({ form, handleSave }: Props) => {
  const initialValues: EFCDataRecord = form;

  const validationSchema = Yup.object({
    state: Yup.string().required("State is required"),
    householdSize: Yup.number().min(1, "Household size must be greater than 0").required("Household size is required"),
    childrenUnder18: Yup.number().required("Children Under 18 is required"),
    childrenInCollege: Yup.number()
      .min(1, "Children in College must be at least 1")
      .required("Children in College is required"),
    parentIncome: Yup.number().required("Parent Income is required"),
    parentUntaxedIncome: Yup.number().required("Parent Untaxed Income is required"),
    parentAssets: Yup.number().required("Parent Assets are required"),
    studentIncome: Yup.number().required("Student Income is required"),
    studentAssets: Yup.number().required("Student Assets are required"),
    ageOfOldestParent: Yup.number()
      .min(1, "Age of oldest parent must be greater than 0")
      .required("Age of oldest parent is required"),
  });

  const handleSubmit = (values: EFCDataRecord, { setSubmitting }: any) => {
    console.log("The values are: ", values);
    const propsParse: CalcProps = {
      state: values.state,
      parentIncome: values?.parentIncome ?? 0,
      householdSize: values?.householdSize ?? 1,
      childrenInCollege: values?.childrenInCollege ?? 1,
      parentUntaxedIncome: values?.parentUntaxedIncome ?? 0,
      ageOfOldestParent: values?.ageOfOldestParent ?? 40,
      maritalStatus: values.maritalStatus,
      parentAssets: values?.parentAssets ?? 0,
      studentAssets: values?.studentAssets ?? 0,
      studentIncome: values?.studentIncome ?? 0,
    };
    console.log("propsParse: ", propsParse);
    const tempEFC = calculateEFC(propsParse);
    if (tempEFC == null) {
      setSubmitting(false);
    } else {
      handleSave(values, tempEFC);
      setSubmitting(false);
    }
  };

  type CalcProps = {
    state: string;
    parentIncome: number;
    householdSize: number;
    childrenInCollege: number;
    parentUntaxedIncome: number;
    ageOfOldestParent: number;
    maritalStatus: string;
    parentAssets: number;
    studentAssets: number;
    studentIncome: number;
  };

  interface EFCInputs {
    state: string;
    parentIncome: number;
    householdSize: number;
    childrenInCollege: number;
    parentUntaxedIncome: number;
    ageOfOldestParent: number;
    maritalStatus: string;
    parentAssets: number;
    studentAssets: number;
    studentIncome: number;
  }

  type IncomeProtectionTableType = {
    [key: number]: {
      [key: number]: number;
    };
  };

  type StateTaxAllowanceType = {
    [key: string]: number;
  };

  const calculateEFC = ({
    state,
    parentIncome,
    householdSize,
    childrenInCollege,
    parentUntaxedIncome,
    ageOfOldestParent,
    maritalStatus,
    parentAssets,
    studentAssets,
    studentIncome,
  }: EFCInputs): number => {
    // 1. Parent's Available Income Calculation
    const totalParentIncome = parentIncome + parentUntaxedIncome;

    // Income Protection Allowance Table (2023-2024)
    const incomeProtectionTable: IncomeProtectionTableType = {
      2: { 1: 19080, 2: 15810 },
      3: { 1: 23760, 2: 20490, 3: 17220 },
      4: { 1: 29340, 2: 26070, 3: 22800, 4: 19520 },
      5: { 1: 34620, 2: 31350, 3: 28080, 4: 24810, 5: 21540 },
      6: { 1: 40490, 2: 37220, 3: 33950, 4: 30680, 5: 27410, 6: 24140 },
    };

    // Get Income Protection Allowance
    let incomeProtectionAllowance: number;
    if (householdSize >= 2 && householdSize <= 6) {
      const collegeCount = Math.min(childrenInCollege, householdSize);
      incomeProtectionAllowance =
        incomeProtectionTable[householdSize][collegeCount] || incomeProtectionTable[householdSize][1];
    } else if (householdSize < 2) {
      incomeProtectionAllowance = 19080; // Minimum for family of 2
    } else {
      // For household size > 6, add amount per additional family member
      incomeProtectionAllowance = 40490 + (householdSize - 6) * 4750;
    }

    // State tax allowance (varies by state)
    const stateTaxAllowanceTable: StateTaxAllowanceType = {
      AL: 0.03,
      AK: 0.02,
      AZ: 0.03,
      AR: 0.04,
      CA: 0.06,
      CO: 0.03,
      CT: 0.06,
      DE: 0.04,
      DC: 0.06,
      FL: 0.02,
      GA: 0.04,
      HI: 0.04,
      ID: 0.04,
      IL: 0.03,
      IN: 0.04,
      IA: 0.04,
      KS: 0.04,
      KY: 0.05,
      LA: 0.03,
      ME: 0.05,
      MD: 0.06,
      MA: 0.05,
      MI: 0.04,
      MN: 0.05,
      MS: 0.04,
      MO: 0.04,
      MT: 0.04,
      NE: 0.04,
      NV: 0.02,
      NH: 0.03,
      NJ: 0.06,
      NM: 0.03,
      NY: 0.07,
      NC: 0.05,
      ND: 0.03,
      OH: 0.04,
      OK: 0.04,
      OR: 0.06,
      PA: 0.04,
      RI: 0.05,
      SC: 0.04,
      SD: 0.02,
      TN: 0.02,
      TX: 0.02,
      UT: 0.04,
      VT: 0.05,
      VA: 0.05,
      WA: 0.02,
      WV: 0.04,
      WI: 0.05,
      WY: 0.02,
      Other: 0.03,
    };

    const stateAllowanceRate = stateTaxAllowanceTable[state] || stateTaxAllowanceTable["Other"];
    const stateTaxAllowanceAmount = totalParentIncome * stateAllowanceRate;

    // Employment expense allowance for working parent(s)
    const employmentAllowance = maritalStatus === "married" ? Math.min(4700, totalParentIncome * 0.35) : 0;

    // Social Security tax allowance (7.65% of income up to 147000)
    const SS_WAGE_CAP = 147000;
    const SS_TAX_RATE = 0.0765;
    const ssTaxAllowance = Math.min(parentIncome, SS_WAGE_CAP) * SS_TAX_RATE;

    // Federal income tax (approximated - could be refined)
    const INCOME_TAX_RATE = 0.1;
    const incomeTaxAllowance = totalParentIncome * INCOME_TAX_RATE;

    // Total Allowances
    const totalAllowances =
      stateTaxAllowanceAmount + incomeProtectionAllowance + employmentAllowance + ssTaxAllowance + incomeTaxAllowance;

    // Available Income
    const availableIncome = Math.max(0, totalParentIncome - totalAllowances);

    // 2. Parent's Asset Contribution
    const getAssetProtectionAllowance = (age: number, isMarried: boolean): number => {
      const MARRIED_MAX_ALLOWANCE = 23800;
      const SINGLE_MAX_ALLOWANCE = 14900;
      const MIN_AGE = 25;
      const MAX_AGE = 65;
      const AGE_RANGE = MAX_AGE - MIN_AGE;

      if (isMarried) {
        if (age <= MIN_AGE) return 0;
        if (age >= MAX_AGE) return MARRIED_MAX_ALLOWANCE;
        return (age - MIN_AGE) * (MARRIED_MAX_ALLOWANCE / AGE_RANGE);
      } else {
        if (age <= MIN_AGE) return 0;
        if (age >= MAX_AGE) return SINGLE_MAX_ALLOWANCE;
        return (age - MIN_AGE) * (SINGLE_MAX_ALLOWANCE / AGE_RANGE);
      }
    };

    const assetProtectionAllowance = getAssetProtectionAllowance(ageOfOldestParent, maritalStatus === "married");

    const discretionaryNetWorth = Math.max(0, parentAssets - assetProtectionAllowance);
    const PARENT_ASSET_RATE = 0.12;
    const parentAssetContribution = discretionaryNetWorth * PARENT_ASSET_RATE;

    // 3. Adjusted Available Income (AAI)
    const aai = availableIncome + parentAssetContribution;

    // 4. Calculate contribution from AAI using Table 8 rates
    interface AAIBracket {
      min: number;
      max: number | null;
      baseAmount: number;
      rate: number;
    }

    const aaiBrackets: AAIBracket[] = [
      { min: -Infinity, max: 0, baseAmount: 0, rate: 0 },
      { min: 0, max: 18900, baseAmount: 0, rate: 0.22 },
      { min: 18900, max: 23700, baseAmount: 4158, rate: 0.25 },
      { min: 23700, max: 28600, baseAmount: 5358, rate: 0.29 },
      { min: 28600, max: 33500, baseAmount: 6769, rate: 0.34 },
      { min: 33500, max: 38300, baseAmount: 8437, rate: 0.4 },
      { min: 38300, max: null, baseAmount: 10357, rate: 0.47 },
    ];

    const bracket = aaiBrackets.find((b) => aai >= b.min && (b.max === null || aai <= b.max)) || aaiBrackets[0];
    const contributionFromAai = bracket.baseAmount + Math.max(0, (aai - bracket.min) * bracket.rate);

    // 5. Divide parents' contribution by number in college
    const parentsContribution = contributionFromAai / childrenInCollege;

    // 6. Student's contribution from income and assets
    const INCOME_PROTECTION_ALLOWANCE = 7600;
    const STUDENT_INCOME_RATE = 0.5;
    const STUDENT_ASSET_RATE = 0.2;

    const studentIncomeContribution = Math.max(0, (studentIncome - INCOME_PROTECTION_ALLOWANCE) * STUDENT_INCOME_RATE);
    const studentAssetContribution = studentAssets * STUDENT_ASSET_RATE;

    // 7. Calculate final EFC
    return Math.round(parentsContribution + studentIncomeContribution + studentAssetContribution);
  };

  return { initialValues, validationSchema, handleSubmit };
};
