import { useCallback, useEffect } from "react";
import { siteLoadingAtom, userLoadingAtom } from "../../shared/recoil/loadingAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStaffAtom, staffUsersAtom } from "../../shared/recoil/userAtoms";
import {
  ProviderProgramRecordTwo,
  SchoolRecord,
  StaffRecord,
  StudentRecord,
  VideoInteractionRecord,
} from "../../shared/types/types";
import { Collection, FunctionName } from "../../shared/types/enums";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import {
  parseSchoolResponse,
  parseStaffResponse,
  parseStudentResponse,
  parseVideoInteractionResponse,
} from "../../shared/utils/parserUtils";
import { useAuth } from "../../shared/contexts/AuthContext";
import { allSchoolStudentsAtom } from "../recoil/studentsAtoms";
import { StaffDataContext } from "../contexts/StaffDataContext";
import { allSchoolsAtom } from "../../shared/recoil/schoolAndDistrictAtoms";
import { videoInteractionsAtom } from "../recoil/videoAtoms";
import { fetchData } from "../../shared/utils/fetchUtils";
import { bookmarkedProgramsAtom, recommendedProgramsAtom } from "../recoil/programAtoms";
import { selectedSchoolIdAtom } from "../recoil/selectedSchoolAtom";

type Props = {
  children: JSX.Element;
};

export const StaffDBProvider = ({ children }: Props) => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const selectedSchoolId = useRecoilValue(selectedSchoolIdAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  const { getFSDocs } = useGetFSDocs();
  const userLoading = useRecoilValue(userLoadingAtom);
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom);
  const setAllSchools = useSetRecoilState(allSchoolsAtom);
  const setStaffUsers = useSetRecoilState(staffUsersAtom);
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);
  const setBookmarkedPrograms = useSetRecoilState(bookmarkedProgramsAtom);
  const currentAuthUser = useAuth();

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      if (userLoading) return;
      setSiteLoading(true);
      if (!currentAuthUser) {
        setSiteLoading(false);
      }
      if (!loggedInStaff) return;
      const initialLoadFiles = await fetchData({
        functionName: FunctionName.GET_STAFF_ON_LOAD_FILES,
        payload: { selectedSchoolId },
      });

      const { students, schools, staff, videoInteractions, recommendedPrograms, bookmarkedPrograms } =
        await initialLoadFiles.json();
      console.log("schools", schools);

      setAllSchoolStudents(parseStudentResponse(students));
      setAllSchools(parseSchoolResponse(schools));
      setStaffUsers(parseStaffResponse(staff));
      setVideoInteractions(parseVideoInteractionResponse(videoInteractions));
      setRecommendedPrograms(recommendedPrograms);
      setBookmarkedPrograms(bookmarkedPrograms);

      setSiteLoading(false);
    };
    loadData();
  }, [
    currentAuthUser,
    loggedInStaff,
    selectedSchoolId,
    setAllSchoolStudents,
    setAllSchools,
    setBookmarkedPrograms,
    setRecommendedPrograms,
    setSiteLoading,
    setStaffUsers,
    setVideoInteractions,
    userLoading,
  ]);

  return <StaffDataContext.Provider value={null}>{children}</StaffDataContext.Provider>;
};
