import { Box, Grid, Typography } from "@mui/material";
import {
  formatCredentialCard,
  formatCredentialCardFontColor,
  formatPercentage,
  formatProgramName,
  numberToCurrency,
} from "../../../utils/formatUtils";
import { Color } from "../../../types/enums";
import { Industry, JobDetailsRecord, ProviderProgramRecordTwo, ReviewRecord } from "../../../types/types";
import logo from "../../../assets/branding/willow-logo.svg";
import CareerTable from "./CareerTable";
import { useCallback, useEffect, useState } from "react";
import { getProgramImage } from "../../../utils/imageUtils";
import industries from "../../../../shared/assets/data/industries.json";

type Props = {
  selectedProgram: ProviderProgramRecordTwo;
  reviews: ReviewRecord | undefined;
  relatedJobs: JobDetailsRecord[];
};

type IndustryCount = {
  [key: number]: number;
};

const ProgramForPrint = ({ selectedProgram, reviews, relatedJobs }: Props) => {
  const [programImage, setProgramImage] = useState<string | undefined>(undefined);
  const [programIndustry, setProgramIndustry] = useState<string>("Unknown Industry");
  const industryData: Industry[] = industries.data;

  const [topRelatedJobs, setTopRelatedJobs] = useState<JobDetailsRecord[]>([]);

  useEffect(() => {
    let sortedJobs = relatedJobs.sort((a, b) => b.medianSalary! - a.medianSalary!);

    if (sortedJobs.length > 3) {
      sortedJobs = sortedJobs.filter((career) => career.bright_outlook);
    }

    if (sortedJobs.length > 3) {
      sortedJobs = sortedJobs.slice(0, 3);
    }

    setTopRelatedJobs(sortedJobs);

    const industryIds = sortedJobs.map((job) => job.industry_id);

    const industryCount = industryIds.reduce<IndustryCount>((acc, industryId) => {
      acc[industryId] = (acc[industryId] || 0) + 1;
      return acc;
    }, {} as IndustryCount);

    let selectedIndustryId = industryIds[0]; // Default to the first one
    for (const [id, count] of Object.entries(industryCount)) {
      const numericId = parseInt(id, 10);
      if (count >= 2) {
        selectedIndustryId = numericId;
        break;
      }
    }

    const selectedIndustry = industryData.find((industry) => industry.id === selectedIndustryId);

    if (!selectedIndustry) {
      console.log("no selected industry");
      return;
    }

    setProgramIndustry(selectedIndustry.title!);
  }, [industryData, relatedJobs, setTopRelatedJobs, setProgramIndustry]);

  const boxTitleSX = {
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 800,
    mt: 1,
  };

  const boxContentSX = {
    fontSize: 14,
  };

  const boxSX = {
    backgroundColor: "#ddd",
    padding: "2px 4px 2px 4px",
    textAlign: "center",
    borderRadius: "12px",
    height: 74,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const programInfo = [
    {
      name: "Admission Percent",
      function: `${formatPercentage({ num: selectedProgram?.admissionPercentCalculated }) ?? "N/A"}`,
    },
    {
      name: "Average Total Program Cost",
      function: selectedProgram.totalCostCalculated
        ? `${numberToCurrency(selectedProgram?.totalCostCalculated ?? "")}`
        : "Unknown",
    },
    {
      name: "Average Graduation Rate",
      function: selectedProgram.completionRateCalculated
        ? `${formatPercentage({ num: selectedProgram.completionRateCalculated })}`
        : "Unknown",
    },
    {
      name: "Total Length of Program",
      function: selectedProgram.durationDisplayCalculated,
    },
    {
      name: "Format Of Instruction",
      function: (() => {
        const formattedModality = selectedProgram.onlineOnly ? "Online" : "In-Person";
        const charLen = 14;
        return formattedModality.length > charLen ? formattedModality.substring(0, charLen) + "..." : formattedModality;
      })(),
    },
    {
      name: "Student Experience",
      function: reviews?.rating ? `${reviews.rating} out of 5` : "Unknown",
    },
  ];

  const loadImage = useCallback(async (willowSubIndustryId: string | undefined | null) => {
    if (!willowSubIndustryId) return;
    try {
      const imageModule = await getProgramImage({ id: willowSubIndustryId });
      setProgramImage(imageModule.default);
    } catch (error) {
      console.error("Error loading image:", error);
      setProgramImage(undefined);
    }
  }, []);

  useEffect(() => {
    loadImage(selectedProgram?.willowSubIndustryId);
  }, [loadImage, selectedProgram]);

  const bottomContainerHeight = 240;

  const determineLongTextFontSize = (text: string) => {
    const textLength = text.length;
    if (textLength < 300) {
      return 18;
    } else if (textLength < 400) {
      return 16;
    } else if (textLength < 500) {
      return 14;
    } else {
      return 12;
    }
  };

  let credentialName = null;
  switch (selectedProgram.credLevel) {
    case "-1":
      credentialName = "Certificate";
      break;
    case "2":
      credentialName = "Associate's Degree";
      break;
    case "3":
      credentialName = "Bachelor's Degree";
      break;
    default:
      credentialName = null;
  }

  return (
    <>
      <Box sx={{ width: "8.3in", height: "10.5in", p: 1 }} className="program-for-print">
        <Box sx={{ pb: 1 }}>
          <img src={logo} alt="logo" width="200px" />
          <Grid container spacing={2}>
            {selectedProgram && selectedProgram.credentialNameCalculated && credentialName && (
              <Grid item>
                <Box
                  sx={{
                    background: formatCredentialCard(selectedProgram?.credentialNameCalculated),
                    padding: "3px",
                    color: formatCredentialCardFontColor(selectedProgram?.credentialNameCalculated),
                    mt: 2,
                    fontSize: 14,
                    borderRadius: 2,
                    border: `2px solid ${formatCredentialCard(selectedProgram?.credentialNameCalculated)}`,
                  }}
                >
                  {credentialName}
                </Box>
              </Grid>
            )}
            {programIndustry && (
              <Grid item>
                <Box
                  sx={{
                    padding: "3px",
                    borderRadius: 2,
                    mt: 2,
                    textTransform: "uppercase",
                    fontSize: 14,
                    border: `2px solid ${Color.MINT}`,
                  }}
                >
                  {programIndustry}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ pb: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontSize: 20 }}>
              {selectedProgram.providerName}
            </Typography>
            <Typography variant="h4" sx={{ pt: 1, fontSize: 26 }}>
              {selectedProgram?.programName!.length > 53
                ? formatProgramName({
                    name: selectedProgram.programName,
                  }).substring(0, 50) + "..."
                : formatProgramName({ name: selectedProgram.programName })}
            </Typography>
            <div
              style={{
                width: "50%",
                border: `4px solid ${Color.CHALKBOARD}`,
                marginTop: "8px",
              }}
            ></div>
          </Grid>
          <Grid item xs={programImage ? 9 : 12}>
            {selectedProgram.programShortDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontSize: determineLongTextFontSize(selectedProgram.programShortDescription),
                }}
              >
                {selectedProgram.programShortDescription!.length > 1000
                  ? selectedProgram.programShortDescription!.substring(0, 1000) + "..."
                  : selectedProgram.programShortDescription!}
              </Typography>
            )}
          </Grid>
          {programImage && (
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                maxHeight: "1.5in",
                pb: 1,
              }}
            >
              <img src={programImage} alt="pathway" />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} sx={{ pb: 1 }}>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: Color.MINT,
                height: 164,
                padding: "2%",
                borderRadius: "12px",
              }}
            >
              <Typography sx={{ ...boxTitleSX, fontSize: 16 }}>Return On Investment (ROI)</Typography>
              <Typography sx={{ fontWeight: 800, fontSize: 32 }}>
                {selectedProgram.willowROICalculated
                  ? `${numberToCurrency(selectedProgram.willowROICalculated)}`
                  : "Unknown"}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                ROI represents how much more money you will make over 10 years if you complete this program.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2} sx={{ p: "2px 12px 2px 12px" }}>
              {programInfo.map((program, index) => (
                <Grid item xs={4} key={index}>
                  <Box sx={boxSX}>
                    <Typography variant="h5" sx={{ ...boxTitleSX, color: Color.CHALKBOARD }}>
                      {program.name}
                    </Typography>
                    <Typography variant="body1" sx={boxContentSX}>
                      {program.function}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <CareerTable relatedJobs={topRelatedJobs} />
          </Grid>
          <Grid item xs={5}>
            {reviews && (
              <Box
                sx={{
                  backgroundColor: Color.CHALKBOARD,
                  height: bottomContainerHeight,
                  px: 2,
                  py: 0.5,
                }}
              >
                <Typography sx={{ ...boxTitleSX, color: Color.CHALK }}>Hear From Students</Typography>
                {reviews.reviewOne && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 14,
                      color: Color.WHITE,
                      fontStyle: "italic",
                    }}
                  >
                    "
                    {reviews.reviewOne.length > 370
                      ? reviews.reviewOne.substring(0, 365) + "..."
                      : reviews.reviewOne.trimEnd()}
                    "
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                backgroundColor: Color.MINT,
                height: bottomContainerHeight,
                px: 2,
                py: 0.5,
              }}
            >
              {selectedProgram.providerDescription && (
                <>
                  <Typography sx={boxTitleSX}>About the Provider</Typography>
                  <Typography
                    sx={{
                      fontSize: determineLongTextFontSize(selectedProgram.providerDescription),
                    }}
                  >
                    {selectedProgram.providerDescription!.length > 800
                      ? selectedProgram.providerDescription!.substring(0, 800) + "..."
                      : selectedProgram.providerDescription!}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProgramForPrint;
