import { useCallback, useEffect, useRef, useState } from "react";
import { fetchData } from "../../utils/fetchUtils";
import { FunctionName } from "../../types/enums";
import { FilterProps, ProviderProgramRecordTwo, SearchedProvider } from "../../types/types";
import { initialFiltersValues } from "../../utils/initialProgramFiltersUtil";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button } from "@mui/material";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import ProgramFilterAndSearchBar from "./Search/ProgramFilterAndSearchBar";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AllProgramResults from "./AllProgramResults";

const AllProgramsContainer = () => {
  console.log("Entire component rerendered");
  const [programs, setPrograms] = useState<ProviderProgramRecordTwo[]>([]);
  const [loading, setLoading] = useState(false);

  // filters and dialog
  const [isEmptyFilters, setIsEmptyFilters] = useState(true);
  const [filters, setFilters] = useState<FilterProps>(initialFiltersValues);
  const [searchedProviders, setSearchedProviders] = useState<SearchedProvider[]>([]);
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);

  console.log("Filters: ", filters);

  // query
  const [allResultsIn, setAllResultsIn] = useState(false);
  const lastVisibleId = useRef<string | null>(null);

  // Programs components
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecordTwo | null>(null);
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const handleQuery = useCallback(
    async ({ loadingMore = false, firstRun }: { loadingMore?: boolean; firstRun: boolean }) => {
      //the allResultsIn reset in the filter click doesn't work because state loads async, this is a temporary fix
      let tempFirstRun = firstRun;
      console.log("All Results In", allResultsIn);
      if (allResultsIn && !tempFirstRun) return;

      tempFirstRun = true;
      if (!loadingMore) {
        setLoading(true);
      }

      try {
        const programsResponse = await fetchData({
          functionName: FunctionName.PROGRAM_QUERY,
          payload: {
            filters,
            lastVisibleIdFromClient: loadingMore ? lastVisibleId.current : null,
            searchedProviderIds: searchedProviders.map((searchedProvider) => searchedProvider.providerId),
          },
        });
        if (!programsResponse) {
          return;
        }
        const { programs: newPrograms, lastVisibleId: lastVisibleIdReturn } = await programsResponse.json();
        console.log("Last Visible ID: ", lastVisibleIdReturn);
        if (lastVisibleIdReturn === null) {
          setAllResultsIn(true);
        } else {
          // Update ref
          lastVisibleId.current = lastVisibleIdReturn;
        }

        if (!newPrograms) {
          return;
        }

        if (!loadingMore) {
          setPrograms(newPrograms);
          return;
        }

        // // Combine existing programs with new ones and filter out duplicates
        const seenIds = new Set();
        const uniquePrograms = [...programs, ...newPrograms].filter((program) => {
          if (seenIds.has(program.id)) {
            return false; // Filter out the duplicate
          }
          seenIds.add(program.id);
          return true; // Keep the unique program
        });
        setPrograms(uniquePrograms); // Set the unique programs
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [allResultsIn, filters, lastVisibleId, programs, searchedProviders],
  );

  const handleFilterClick = useCallback(() => {
    setPrograms([]);
    setAllResultsIn(false);
    setIsEmptyFilters(false);
    handleQuery({ loadingMore: false, firstRun: true });
    if (mobile) setExpanded(false);
  }, [setExpanded, handleQuery, mobile, setIsEmptyFilters, setAllResultsIn, setPrograms]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 5) {
        console.log("Handle Quewry from Scroll");
        handleQuery({ loadingMore: true, firstRun: false });
      }
    },
    [handleQuery],
  );

  return (
    <Box>
      <>
        {mobile ? (
          // Mobile view
          <Accordion expanded={expanded} onChange={() => setExpanded((prevValue) => !prevValue)} sx={{ m: 0, p: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="program-filters" id="program-filters">
              Filters
            </AccordionSummary>
            {/* Height could be managed better here */}
            <AccordionDetails sx={{ m: 0, p: 0, overflowY: "auto", height: "calc(100vh - 280px)" }}>
              <ProgramFilterAndSearchBar
                isDialogMode={false}
                filters={filters}
                setFilters={setFilters}
                handleFilterButtonClick={handleFilterClick}
                searchedProviders={searchedProviders}
                setSearchedProviders={setSearchedProviders}
                showFiltersDialog={showFiltersDialog}
                setShowFiltersDialog={setShowFiltersDialog}
                loading={loading}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          // Desktop View
          <>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", pt: 2, pr: 1 }}>
              {!isEmptyFilters && (
                <Box sx={{ flex: "none" }} textAlign="center">
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowFiltersDialog(true)}
                  >
                    Change Filters
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              {
                <ProgramFilterAndSearchBar
                  isDialogMode={programs.length !== 0}
                  filters={filters}
                  setFilters={setFilters}
                  handleFilterButtonClick={handleFilterClick}
                  searchedProviders={searchedProviders}
                  setSearchedProviders={setSearchedProviders}
                  showFiltersDialog={showFiltersDialog}
                  setShowFiltersDialog={setShowFiltersDialog}
                  loading={loading}
                />
              }
            </Box>
          </>
        )}

        <Box sx={{ p: 1, height: "85vh", overflowY: "auto" }} onScroll={handleScroll}>
          <AllProgramResults
            showFiltersDialog={showFiltersDialog}
            loading={loading}
            isEmptyFilters={isEmptyFilters}
            setSelectedProgramDetail={setSelectedProgramDetail}
            programs={programs}
          />
        </Box>
        {selectedProgramDetail && (
          <ProgramDetailsDialog selectedProgram={selectedProgramDetail} setSelectedProgram={setSelectedProgramDetail} />
        )}
      </>
    </Box>
  );
};

export default AllProgramsContainer;
