import { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import useAddFSDoc from "../../hooks/db/useAddFSDoc";
import { Collection, FunctionName } from "../../types/enums";
import { Box, TextField, Button, Typography, LinearProgress, Container } from "@mui/material";
import DuplicateVideoManager from "./DuplicateVideoManager";
import VideoExplorer from "./VideoExplorer";
import { fetchData } from "../../utils/fetchUtils";

const UploadVideosContainer = () => {
  const [onetCode, setOnetCode] = useState("");
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addFSDoc } = useAddFSDoc();
  const storage = getStorage();

  const handleUpload = async () => {
    try {
      const trimmedOnetCode = onetCode.trim();

      if (!trimmedOnetCode || !videoFile) {
        alert("Please provide both OnetCode and a video file.");
        return;
      }

      setIsLoading(true);

      const filename = `${crypto.randomUUID()}.mp4`;

      // Upload the original video to Firebase Storage
      const storageRef = ref(storage, `/career-videos/${filename}`);
      await uploadBytes(storageRef, videoFile);

      const now = new Date();

      // Add metadata to Firestore
      await addFSDoc({
        col: Collection.CAREER_VIDEOS,
        data: {
          active: true,
          createdAt: now.toISOString(),
          fileName: filename,
          onet: trimmedOnetCode,
          source: "tiktok",
        },
      });

      // Trigger transcoding for the uploaded video
      await triggerTranscoding(filename);

      alert(`Video (${filename}) uploaded, transcoded, and metadata added successfully!`);

      setOnetCode("");
      setVideoFile(null);
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("There was an error uploading the video. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const triggerTranscoding = async (fileName: string) => {
    try {
      const response = await fetchData({
        functionName: FunctionName.TRANSCODER_REQUEST,
        payload: { fileName },
      });
      console.log("Transcoding response:", response);
    } catch (error) {
      console.error("Error triggering transcoding:", error);
      throw new Error("Failed to trigger transcoding.");
    }
  };

  return (
    <Container maxWidth={false}>
      <Box width={500} mt={4} p={3} boxShadow={3} borderRadius={2} bgcolor="background.paper">
        <Typography variant="h5" gutterBottom>
          Upload Video to Server & Metadata to Firestore
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Please upload the videos one by one, and avoid using multiple tabs to upload them simultaneously.
        </Typography>

        <Box mt={3}>
          <TextField
            fullWidth
            label="Onet Code"
            variant="outlined"
            value={onetCode}
            onChange={(e) => setOnetCode(e.target.value)}
            placeholder="Enter Onet Code"
            disabled={isLoading}
            margin="normal"
          />

          <Button variant="contained" component="label" disabled={isLoading} fullWidth sx={{ mb: 2 }}>
            {videoFile ? videoFile.name : "Select Video File"}
            <input
              type="file"
              hidden
              accept="video/mp4"
              onChange={(e) => setVideoFile(e.target.files ? e.target.files[0] : null)}
            />
          </Button>

          {isLoading && <LinearProgress />}

          <Button variant="contained" color="primary" fullWidth onClick={handleUpload} disabled={isLoading}>
            {isLoading ? "Uploading..." : "Upload Video"}
          </Button>
        </Box>
      </Box>

      <Box mt={4}>
        <DuplicateVideoManager />
      </Box>

      <Box mt={4}>
        <VideoExplorer />
      </Box>
    </Container>
  );
};

export default UploadVideosContainer;
